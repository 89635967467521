/**
 * Button class adding color='accent' option on top of
 * MaterialUI button
 */

import * as React from 'react';
import clsx from 'clsx';
import {
  makeStyles,
  Button as MaterialUIButton,
  ButtonProps as MaterialUIButtonProps
} from '@material-ui/core';

export type ButtonProps = Omit<MaterialUIButtonProps, 'color'> & {
    color?: 'accent' | MaterialUIButtonProps['color']
}

const useStyles = makeStyles((theme) => ({
  accentButton: {
    backgroundColor: theme.palette.accent.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.accent.dark
    }
  }
}));

export const Button = React.forwardRef(function Button (props: ButtonProps, ref: React.Ref<any>): JSX.Element {
  const { color, className, children, ...other } = props;
  const classes = useStyles();
  return (
    <MaterialUIButton
      ref={ref}
      className={clsx(
        className,
        color === 'accent' ? classes.accentButton : ''
      )}
      color={color !== 'accent' ? color : undefined}
      {...other}
    >
      {children}
    </MaterialUIButton>
  );
});

export default Button;
