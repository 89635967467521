import * as React from 'react';
import { makeStyles, Container } from '@material-ui/core';
import { Link } from '../i18n';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.grey[500]
  },
  footerContent: {
    padding: theme.spacing(4, 0),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end'
  }
}));

export default function Footer () {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container>
        <div className={classes.footerContent}>
          <Link color='inherit' href='https://www.databravo.com/privacy-policy'>Privacy Policy</Link>
          &nbsp;&amp;&nbsp;
          <Link color='inherit' href='https://www.databravo.com/terms-of-service'>Terms of Service</Link>
        </div>
      </Container>
    </div>
  );
}
