import * as React from 'react';
import {
  makeStyles,
  Dialog, DialogContent,
  FormControlLabel,
  Checkbox,
  DialogActions,
  DialogContentText,
  CircularProgress
} from '@material-ui/core';
import FlexFill from '../FlexFill';
import Header from './Header';
import Footer from './Footer';
import clsx from 'clsx';
import { useUser } from 'lib/user';

import { Link } from '../i18n';
import LoadingButton from 'components/LoadingButton';

const TERMS_OF_SERVICE_URL = 'https://www.databravo.com/terms-of-service';
const PRIVACY_POLICY_URL = 'https://www.databravo.com/privacy-policy';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    position: 'relative'
  },
  loginRequired: {
    overflow: 'hidden'
  },
  loginRequiredOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: theme.zIndex.appBar - 1,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    backdropFilter: 'blur(4px)',
    WebkitTapHighlightColor: 'transparent',
    color: 'rgba(256, 256, 256, 0.9)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  loginRequiredOverlaySpinner: {
    marginRight: theme.spacing(1)
  }
}));

interface LoginRequiredProps {
  isAuthenticating?: boolean
}

function LoginRequired ({ isAuthenticating }: LoginRequiredProps) {
  const classes = useStyles();
  return (
    <div className={classes.loginRequiredOverlay}>
      {isAuthenticating
        ? (
      <>
        <CircularProgress
          className={classes.loginRequiredOverlaySpinner}
          size={16}
          color='inherit'
        />
        Authenticating...
      </>
          )
        : 'Logged out'}
    </div>
  );
}

function TosConsentDialog () {
  const { user, logOut, update } = useUser();
  const [accepted, setAccepted] = React.useState(false);
  const [logOutLoading, setLogOutLoading] = React.useState(false);
  const [acceptLoading, setAcceptLoading] = React.useState(false);

  const handleDecline = async () => {
    setLogOutLoading(true);
    logOut();
  };

  const handleAccept = async () => {
    try {
      setAcceptLoading(true);
      await update({ hasAcceptedTos: true });
    } catch (err) {
      alert('Failed to update user');
    } finally {
      setAcceptLoading(false);
    }
  };

  return (
    <Dialog open={user ? !user.hasAcceptedTos : false}>
      <DialogContent>
        <DialogContentText>
          We kindly request you to read and agree to our <Link href={TERMS_OF_SERVICE_URL}>Terms of Service</Link> and <Link href={PRIVACY_POLICY_URL}>Privacy Policy</Link> in order to use our service.
        </DialogContentText>
        <FormControlLabel
          control={
            <Checkbox
              checked={accepted}
              onChange={e => setAccepted(e.target.checked)}
              color='primary'
            />
          }
          label='I agree to the Terms of Service and Privacy Policy'
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton
          pending={logOutLoading}
          onClick={handleDecline}
          variant='outlined'
          size='large'
        >
          Decline and logout
        </LoadingButton>
        <LoadingButton
          pending={acceptLoading}
          disabled={!accepted}
          onClick={handleAccept}
          color='secondary'
          variant='contained'
          size='large'
          autoFocus
        >
          Accept and continue
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

interface LayoutProps {
  children: React.ReactNode
  active?: 'dashboard' | 'exports'
  className?: string
  loginRequired?: boolean
  loginRedirectUrl?: string
}

export default function Layout ({ children, active, className, loginRequired, loginRedirectUrl }: LayoutProps) {
  const classes = useStyles();
  const { user, loading: isAuthenticating } = useUser();
  const showLoginRequiredOverlay = loginRequired && !user;
  return (
    <div className={clsx(classes.root, { [classes.loginRequired]: showLoginRequiredOverlay }, className)}>
      <Header active={active} loginRedirectUrl={loginRedirectUrl} />
      {showLoginRequiredOverlay ? <LoginRequired isAuthenticating={isAuthenticating} /> : null}
      {children}
      <FlexFill />
      <Footer />
      <TosConsentDialog />
    </div>
  );
}
