/**
 * Adapted from https://github.com/mui-org/material-ui/pull/21389
 * Replace when Material-UI v5 is released and included
 */

import * as React from 'react';
import clsx from 'clsx';
import {
  makeStyles,
  CircularProgress
} from '@material-ui/core';

import {
  Button,
  ButtonProps
} from './Button';

type LoadingButtonProps = ButtonProps & {
  /**
   * If `true`, the pending indicator will be shown.
   */
  pending?: boolean;
  /**
   * Element placed before the children if the button is in pending state.
   */
  pendingIndicator?: React.ReactNode;
  /**
   * The pending indicator can be positioned on the start, end, or the center of the button.
   */
  pendingPosition?: 'start' | 'end' | 'center';

  component?: string | React.ElementType
}

export const useStyles = makeStyles(() => ({
  /* Styles applied to the root element. */
  root: {},
  /* Styles applied to the root element if `pending={true}`. */
  pending: {},
  start: {},
  end: {},
  center: {},
  /* Styles applied to the pendingIndicator element. */
  pendingIndicator: {
    position: 'absolute',
    visibility: 'visible',
    display: 'flex',
    /* Styles applied to the pendingIndicator element if `pendingPosition="start"`. */
    '$start &': {
      left: 14
    },
    /* Styles applied to the pendingIndicator element if `pendingPosition="end"`. */
    '$end &': {
      right: 14
    },
    /* Styles applied to the pendingIndicator element if `pendingPosition="center"`. */
    '$center &': {
      left: '50%',
      transform: 'translate(-50%)'
    }
  },
  /* Styles applied to the endIcon element if `pending={true}` and `pendingPosition="end"`. */
  endIcon: {
    '$pending$end &': {
      visibility: 'hidden'
    }
  },
  /* Styles applied to the startIcon element if `pending={true}` and `pendingPosition="start"`. */
  startIcon: {
    '$pending$start &': {
      visibility: 'hidden'
    }
  },
  /* Styles applied to the label element if `pending={true}` and `pendingPosition="center"`. */
  label: {
    '$pending$center &': {
      visibility: 'hidden'
    }
  }
}));

const PendingIndicator = <CircularProgress color='inherit' size={16} />;

export default React.forwardRef(function LoadingButton (props: LoadingButtonProps, ref: React.Ref<any>) {
  const classes = useStyles();
  const {
    children,
    className,
    disabled = false,
    pending = false,
    pendingIndicator = PendingIndicator,
    pendingPosition = 'center',
    ...other
  } = props;

  return (
    <Button
      className={clsx(
        classes.root,
        {
          [classes.pending]: pending,
          [classes.start]: pendingPosition === 'start',
          [classes.end]: pendingPosition === 'end',
          [classes.center]: pendingPosition === 'center'
        },
        className
      )}
      disabled={disabled || pending}
      ref={ref}
      classes={{
        startIcon: classes.startIcon,
        endIcon: classes.endIcon,
        label: classes.label
      }}
      {...other}

    >
      {pending && (
        <div
          className={clsx(
            classes.pendingIndicator,
            classes.pendingIndicator
          )}
        >
          {pendingIndicator}
        </div>
      )}

      {children}
    </Button>
  );
});
