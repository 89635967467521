import * as React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    flex: 1
  }
});

export default function FlexFill () {
  const classes = useStyles();
  return <div className={classes.root} />;
}
