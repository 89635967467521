import * as React from 'react';
import { Link, useRouter } from '../i18n';
import {
  makeStyles,
  AppBar,
  Toolbar,
  Avatar,
  Container,
  IconButton,
  // Skeleton, // uncomment when moving to material UI v5 (see https://github.com/mui-org/material-ui/pull/22740)
  Menu,
  MenuItem,
  useScrollTrigger
} from '@material-ui/core';
// Remove when upgrading to material UI v5
import { Skeleton } from '@material-ui/lab';
import { useUser } from '../../lib/user';
import LogoIcon from '../../public/logo-text.svg?svgr';
import { User } from '@wooindex/common/types';
import LoadingButton from '../LoadingButton';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  toolbar: {
    transition: 'min-height 200ms'
  },
  offset: theme.mixins.toolbar,
  userLink: {
    fontSize: '1.2em',
    marginRight: theme.spacing(4),
    padding: theme.spacing(1, 0),
    '&:hover': {
      textDecoration: 'none'
    },
    '&$active': {
      color: theme.palette.accent.main
    }
  },
  active: {},
  headerLeft: {
    flexBasis: 0,
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-start'
  },
  headerCenter: {
    flexBasis: 0,
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center'
  },
  headerRight: {
    flexBasis: 0,
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));

interface UserInfoProps {
  user: User | null
  loading?: boolean
  loginRedirectUrl?: string
}

function UserInfo ({ user, loading, loginRedirectUrl }: UserInfoProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [loginPending, setLoginPending] = React.useState(false);
  const router = useRouter();

  const isMenuOpen = Boolean(anchorEl);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const redirectUrl = loginRedirectUrl || `/${router.locale}${router.asPath}`;

  return loading
    ? (
      <Skeleton variant='circle'>
        <IconButton edge='end' size='small'>
          <Avatar />
        </IconButton>
      </Skeleton>
      )
    : user
      ? (
        <>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            getContentAnchorEl={null}
            open={isMenuOpen}
            onClose={handleMenuClose}
          >
            <MenuItem disabled>{user.name}</MenuItem>
            <MenuItem component={Link} href='/subscription' onClick={handleMenuClose}>My Subscription</MenuItem>
            <MenuItem component='a' href='/api/auth/logout' onClick={handleMenuClose}>Logout</MenuItem>
          </Menu>
          <IconButton onClick={handleMenuOpen} edge='end' size='small'>
            <Avatar alt={user.name} src={user.picture} />
          </IconButton>
        </>
        )
      : (
        <LoadingButton
          component='a'
          variant='contained'
          color='secondary'
          size='large'
          href={`/api/auth/login?redirectTo=${encodeURIComponent(redirectUrl)}`}
          aria-label='login'
          onClick={() => setLoginPending(true)}
          pending={loginPending}
        >
          Login
        </LoadingButton>
        );
}

interface HeaderProps {
  active?: 'dashboard' | 'exports'
  loginRedirectUrl?: string
}

export default function Header ({ active, loginRedirectUrl }: HeaderProps) {
  const { user, loading } = useUser();
  const trigger = useScrollTrigger({ threshold: 20, disableHysteresis: true });
  const classes = useStyles();
  return (
    <>
      <AppBar position='fixed' color='inherit' className={classes.root} elevation={trigger ? 4 : 0}>
        <Container>
          <Toolbar
            disableGutters
            className={classes.toolbar}
            variant={trigger ? 'dense' : undefined}
          >
            <div className={classes.headerLeft}>
              <Link
                color='inherit'
                className={clsx(classes.userLink, { [classes.active]: active === 'dashboard' })}
                href='/dashboard'
                aria-label='exports'
              >
                Home
              </Link>
              <Link
                color='inherit'
                className={clsx(classes.userLink, { [classes.active]: active === 'exports' })}
                href='/dashboard/exports'
                aria-label='exports'
              >
                Exports
              </Link>
            </div>
            <div className={classes.headerCenter}>
              <Link href='/dashboard' aria-label='home'>
                <LogoIcon alt='dataBravo logo' height={24} />
              </Link>
            </div>
            <div className={classes.headerRight}>
              <UserInfo user={user} loading={loading} loginRedirectUrl={loginRedirectUrl} />
            </div>
          </Toolbar>
        </Container>
      </AppBar>
      <div className={classes.offset} />
    </>
  );
}
